<template>
  <div :style="getElementStyle">
    <!-- Below code is for Before Field View -->
    <div v-if="!isDefalutPos">
      <div style="display: flex; align-items: center">
        <icons
          v-if="isIconRequired"
          :iconName="data.icon_name"
          class="mr-1"
        ></icons>
        <label for="text" style="margin-right: 5px; white-space: nowrap"
          >{{ data.label }}
        </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-right: 5px"
        >
          *
        </span>
        <span>
          <i
            class="el-icon-copy-document"
            v-if="!isDataTableField"
            @click="copyDocument"
          ></i
        ></span>
        <span v-if="data.description" style="margin-right: 5px">
          <i class="el-icon-info" :title="data.description"></i>
        </span>

        <input
          :style="getStyle"
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :suffix-icon="data.properties.el_icon"
          class="slv"
        />
      </div>
      <div>
        <span v-if="isActive || isDataTableField" class="setting-icon">
          <i class="el-icon-s-tools" @click="openSettings"></i>
        </span>
      </div>
    </div>

    <!-- Below Code is for Top Field View -->

    <el-row align="middle" v-else>
      <el-col
        v-if="!isInDataTable"
        :span="showLabel && !isDefalutPos ? 4 : data.description ? 20 : 24"
        ref="colRef"
        :style="computedStyles"
        class="bottom-space"
      >
        <icons
          v-if="isIconRequired"
          :iconName="data.icon_name"
          class="mr-1"
        ></icons>
        <label for="text">{{ data.label }} </label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <span v-if="!isDataTableField">
          <i
            class="el-icon-copy-document"
            v-if="!isDataTableField"
            @click="copyDocument"
          >
          </i>
        </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>

      <!-- <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <span v-if="data.description">
          <i
            class="el-icon-info"
            :title="data.description"
          ></i>
        </span>
      </el-col> -->
      <el-row v-if="isActive || isDataTableField" class="setting-icon">
        <i class="el-icon-s-tools" @click="openSettings"></i>
      </el-row>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <input
          :style="{ 'text-align': inputTextAlignment(), ...getStyle }"
          :placeholder="data.placeholder"
          v-model="selectedValue"
          :suffix-icon="data.properties.el_icon"
          class="slv"
        />
      </el-col>
    </el-row>

    <!--<div>
    <div>
      
      {{data.label}}
      <span v-if="data.description">
        <i class="el-icon-info" :title="data.description" />
      </span>
      <span v-if="isActive || isDataTableField" class="setting-icon" @click="openSettings">
        <i class="el-icon-s-tools" />
      </span>
    </div>
    <el-input :style="getStyle" :placeholder="data.placeholder" v-model="selectedValue"></el-input>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FieldStylesHelper from "@/mixins/FieldStylesHelper";
import { fetchGlobalVariableById } from "@/repo/globalVariables";

export default {
  name: "SingleLineTextView",
  components: {},
  props: [
    "data",
    "value",
    "isActive",
    "isDataTableField",
    "isInDataTable",
    "isIconRequired",
    "labelAlignments"
  ],
  mixins: [FieldStylesHelper],

  computed: {
    ...mapGetters("globalVariables", ["getGlobalVariableById"]),

    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if(this.labelAlignments || this.data?.styles?.labelAlignments){
        styles.justifyContent = this.labelAlignments || this.data?.styles?.labelAlignments;
        }

      // Add other dynamic styles if needed

      return styles;
    },
    getStyle() {
      let inputStyle = "";
      if (this.data.styles) {
        inputStyle += this.data.styles.input_background
          ? `background-color: ${this.data.styles.input_background}`
          : "";
      }

      inputStyle += ";";
      // inputStyle = inputStyle + `width: ${this.data.width}px !important;`;
      return inputStyle;
    },
  },

  data() {
    return {
      validations: [],
      options: [],
      isList: false,
      // isDefalutPosq: true,
      showLabel: true,
      inputTextAlignmentValue: "left",
      // validations: [],
      // isDefalutPos: true,
      // showLabel: true
    };
  },
  mounted() {
    if (this.data.styles) {
      if (this.data.styles && this.data.styles.labelStyle) {
        if (this.data.styles.labelStyle == "hide") {
          this.showLabel = false;
        }
        // if (this.data.styles.labelStyle == "right") {
        //   this.isDefalutPos = false;
        // }
      }
    }

    this.options = this.data.options || [];
    if (this.data.is_global_variable && this.data.global_variable_id) {
      this.setGlobalVariableData();
    }
  },
  methods: {
    updateIcon(iconName) {
      this.$emit("update:data", { ...this.data, icon_name: iconName });
    },
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    inputTextAlignment() {
      return this.data.styles && this.data.styles.inputTextAlignments
        ? this.data.styles.inputTextAlignments
        : "left"; // Set a default value if not provided
    },
    async setGlobalVariableData() {
      let params = {
        id: this.data.global_variable_id,
      };
      // await this.$store.dispatch(
      //   "globalVariables/fetchGlobalVariableById",
      //   params
      // );
      let globalVariable = await fetchGlobalVariableById(params);
      if (globalVariable) {
        if (globalVariable.input_type == "SINGLE_LINE_TEXT") {
          // this.isList = true;
          this.data.selectedValue = globalVariable.value;
          // this.data.is_list = true;
        } else {
          // this.data.selectedValue = this.getGlobalVariableById.value;
          // this.data.is_list = false;
        }
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    // isActive: {
    //   immediate: true,
    //   handler(val, oldVal) {
    //   }
    // },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.slv {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}
</style>
