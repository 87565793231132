var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(!_vm.isInDataTable)?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[_c('span',{staticStyle:{"display":"flex"}},[(_vm.isIconRequired)?_c('icons',{staticClass:"mr-1",attrs:{"iconName":_vm.data.icon_name}}):_vm._e(),_c('label',{attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(!_vm.isDataTableField)?_c('i',{staticClass:"el-icon-copy-document",on:{"click":_vm.copyDocument}}):_vm._e()],1),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_vm._e(),(_vm.isActive || _vm.isDataTableField)?_c('el-row',{staticClass:"setting-icon"},[_c('i',{staticClass:"el-icon-s-tools",on:{"click":_vm.openSettings}})]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(_vm.data.inputType == 'DATE')?[_c('el-date-picker',{style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-date","height":_vm.data.height,"width":_vm.data.width,"type":"date","placeholder":_vm.data.placeholder},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]:_vm._e(),(_vm.data.inputType == 'TIME')?[_c('el-time-picker',{style:(_vm.getStyle),attrs:{"arrow-control":"","prefix-icon":"el-icon-time","height":_vm.data.height,"width":_vm.data.width,"type":"date","placeholder":_vm.data.placeholder},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]:_vm._e(),(_vm.data.inputType == 'DATE_TIME')?[_c('el-date-picker',{style:(_vm.getStyle),attrs:{"type":"datetime","prefix-icon":"el-icon-date","height":_vm.data.height,"width":_vm.data.width,"placeholder":_vm.data.placeholder},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }